import Hls, { HlsConfig, MediaPlaylist } from 'hls.js';
import {
  Drm,
  PlayerEventCallback,
  BasePlayer,
  Quality,
  SubtitleTrack,
} from './BasePlayer';
import {
  parseHtmlDurationRange,
  Logger,
  uInt8ArrayToString,
  waitFor,
  base64EncodeUint8Array,
  base64DecodeUint8Array,
} from './util';
import * as dashjs from 'dashjs';

let html5PlayerInitialized = false;

class Html5Player implements BasePlayer {
  private player?: HTMLVideoElement;
  private isPaused: boolean = false;
  private readonly debug: boolean = true;
  private buffered: number = 0;
  private duration: number = 0;
  private videoWidth: number = 0;
  private videoHeight: number = 0;
  private audioTracks: MediaPlaylist[] = [];
  private subtitleTracks: MediaPlaylist[] = [];
  private readonly playerId: string = 'web_player';
  private eventCallback?: PlayerEventCallback;
  private hlsSourceUrl: string | null = null;
  private currentAudioTrackIndex: number | null = null;
  private currentHlsInstance: Hls | null = null;
  private currentDashInstance: dashjs.MediaPlayerClass | null = null;
  private hasBufferAppendingError: boolean = false;
  private encryptedEventListener?: (
    this: HTMLVideoElement,
    ev: MediaEncryptedEvent
  ) => any;

  private readonly logger: Logger = new Logger('HTML5Player');

  isInitialized(): boolean {
    return html5PlayerInitialized;
  }

  viewElement(): HTMLElement {
    this.logger.log('[Player viewElement]');
    const playerWrapper = document.createElement('div');
    playerWrapper.style.width = '100%';
    playerWrapper.style.height = '100%';

    const videoElement = document.createElement('video');
    videoElement.id = this.playerId;
    videoElement.style.width = '100%';
    videoElement.style.height = '100%';
    this.logger.log(`creating video element: ${videoElement.id}`);

    videoElement.autoplay = true;

    playerWrapper.appendChild(videoElement);

    playerWrapper.addEventListener('contextmenu', (event) =>
      event.preventDefault()
    );

    this.logger.log('returning html5 player');
    return playerWrapper;
  }

  async init(): Promise<void> {
    this.logger.log('[Player init]');
    if (html5PlayerInitialized) {
      this.logger.log('init: Player already initialized, no need to call init');
      return;
    }
    if (!(document.getElementById(this.playerId) instanceof HTMLVideoElement)) {
      this.logger.log('Error: player id not found at init()');
      return;
    }
    this.player = document.getElementById(this.playerId) as HTMLVideoElement;
    this.player.controls = false;
    this.addPlayerListeners();
    this.logger.log('found player id at init');
    html5PlayerInitialized = true;
  }

  addPlayerListeners(): void {
    this.logger.log('[Player addPlayerListeners2]');
    if (this.player == null) {
      this.logger.log('addPlayerListeners: player not initialized');
      return;
    }

    const player = this.player;
    this.player.addEventListener('loadeddata', () => {
      this.logger.log(`loadeddata triggered, readystate: ${player.readyState}`);

      if (player.readyState >= 2) {
        player
          .play()
          .then(() => {
            this.logger.log(`Starting play on readystate ${player.readyState}`);
          })
          .catch((e: any) => {
            this.logger.log(e);
          });
      }
    });
    this.player.addEventListener('error', (event) => {
      this.logger.log(event);
      this.eventCallback?.({ key: this.playerId, type: 'error' });
    });
    this.player.addEventListener('play', (event) => {
      this.logger.log(event);
      this.eventCallback?.({ key: this.playerId, type: 'play' });
    });
    this.player.addEventListener('ended', (event) => {
      this.logger.log(event);
      this.eventCallback?.({ key: this.playerId, type: 'completed' });
    });
    this.player.addEventListener('durationchange', (event) => {
      this.logger.log(event);
      this.duration = 0;
      this.videoHeight = 100;
      this.videoWidth = 200;
      this.buffered = 10;
    });

    this.player.addEventListener('pause', (event) => {
      this.logger.log(event);
      this.eventCallback?.({ key: this.playerId, type: 'pause' });
    });

    this.player?.addEventListener('waiting', () => {
      this.eventCallback?.({ key: this.playerId, type: 'bufferingStart' });
    });

    this.player?.addEventListener('canplaythrough', () => {
      this.eventCallback?.({ key: this.playerId, type: 'bufferingEnd' });
    });
    this.player?.addEventListener('progress', (event) => {
      this.logger.log(event);
      if (this.player == null) {
        return;
      }
      this.logger.log(`duration time: ${this.duration}`);
      const buffered = this.player.buffered;
      const bufferedRanges = parseHtmlDurationRange(buffered);
      this.logger.log(`Buffered Ranges: ${JSON.stringify(bufferedRanges)}`);
      if (bufferedRanges.length > 0) {
        this.eventCallback?.({
          key: this.playerId,
          type: 'bufferingUpdate',
          buffered: bufferedRanges,
        });
      }
    });
    this.player.addEventListener('loadedmetadata', (event) => {
      if (this.player == null) {
        return;
      }
      this.videoWidth = this.player.videoWidth;
      this.videoHeight = this.player.videoHeight;
      this.duration = this.getDurationInMilliseconds();
      this.logger.log(
        `html5Player: loadedmetadata duration: ${this.duration} width: ${this.videoWidth} height:  ${this.videoHeight}`
      );
      if (this.debug) {
        this.logger.log(event);
      }

      this.eventCallback?.({
        key: this.playerId,
        type: 'initialized',
        duration: this.duration,
        size: {
          width: this.videoWidth,
          height: this.videoHeight,
        },
      });
    });
    this.player.addEventListener('resize', (event) => {
      this.logger.log(event);
      if (this.player == null) {
        return;
      }

      this.eventCallback?.({
        key: this.playerId,
        type: 'updateVideoSize',
        bitrate: -1,
        size: {
          width: this.player.videoWidth,
          height: this.player.videoHeight,
        },
      });
    });
  }

  async destroy(): Promise<void> {
    this.logger.log('Player destroy]');

    if (this.player == null) {
      this.logger.log('destroy: Player not initialized');
      return;
    }
    this.logger.log('calling destroy');
    this.player?.pause();
    this.player.removeAttribute('src'); // empty source

    // are bellow lines necessary?
    this.player.load();
    html5PlayerInitialized = false;
  }

  private getSourceType(url: string): string {
    if (url.includes('.mp4')) {
      return 'video/mp4';
    }
    return 'application/x-mpegurl';
  }

  clearCurrentSource(): void {
    this.logger.log('clearCurrentSource');

    this.duration = 0;
    this.videoHeight = 0;
    this.videoHeight = 0;
    this.subtitleTracks = [];
    this.audioTracks = [];

    if (this.player == null) {
      return;
    }
    while (this.player.firstChild != null) {
      this.player.removeChild(this.player.firstChild);
    }
    if (this.currentHlsInstance != null) {
      this.currentHlsInstance.destroy();
      this.currentHlsInstance = null;
    }
    if (this.currentDashInstance != null) {
      this.currentDashInstance.reset();
      this.currentDashInstance = null;
    }

    if (this.encryptedEventListener != null) {
      this.logger.log('removing encrypted event listener');
      this.player.removeEventListener('encrypted', this.encryptedEventListener);
    }
  }

  async playClearStream(url: string): Promise<void> {
    if (this.player == null) {
      return;
    }

    try {
      this.logger.log('native html5');

      const source = document.createElement('source');
      source.src = url;
      source.type = this.getSourceType(url);
      this.player.appendChild(source);
      this.player.load();
    } catch (e) {
      this.logger.error('error at playClearStream', e);
    }
  }

  getLicenseUrl(drm: Drm): string {
    const authorizationHeader = drm.headers as { Authorization?: string };
    const jwtToken = (authorizationHeader.Authorization ?? '').replace(
      'Bearer ',
      ''
    );
    return `${drm.data.licenseUrl}${
      drm.data.licenseUrl.includes('?') ? `&jwt=${jwtToken}` : ''
    }
    `;
  }

  private bypassAuthentication(licenseUrl: string): boolean {
    return (
      licenseUrl.includes('lic.meevu.net') ||
      licenseUrl.includes('uniqcast.cryptoguard.com')
    );
  }

  private async getFairplayCertificate(
    serverCertificatePath: string
  ): Promise<ArrayBuffer> {
    try {
      this.logger.log(`Loading certificate at ${serverCertificatePath}`);
      const response = await fetch(serverCertificatePath);
      return await response.arrayBuffer();
    } catch (e) {
      this.logger.error(
        `Could not load certificate at ${serverCertificatePath}`
      );
      throw e;
    }
  }

  private async getLicenseResponse(
    licenseUrl: string,
    authorization: string,
    assetId: string,
    spc: Iterable<number>
  ): Promise<Uint8Array> {
    this.logger.log('getLicenseResponse', assetId);
    const headers = new Headers({
      'Content-type': 'application/json',
    });
    if (!this.bypassAuthentication(licenseUrl)) {
      headers.set('Authorization', authorization);
    }
    const licenseResponse = await fetch(licenseUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        assetId,
        spc: base64EncodeUint8Array(new Uint8Array(spc)),
      }),
    });
    const license = await licenseResponse.json();
    return base64DecodeUint8Array(license.ckc);
  }

  private async getFairplayMediaKeys(
    initDataType: string,
    certificateUrl: string
  ): Promise<MediaKeys> {
    this.logger.log('Creating MediaKeys');
    const access = await navigator.requestMediaKeySystemAccess(
      'com.apple.fps.1.0',
      [
        {
          initDataTypes: [initDataType],
          videoCapabilities: [
            {
              contentType: 'application/vnd.apple.mpegurl',
              robustness: '',
            },
          ],
          distinctiveIdentifier: 'not-allowed',
          persistentState: 'not-allowed',
          sessionTypes: ['temporary'],
        },
      ]
    );

    const keys = await access.createMediaKeys();
    const cert = await this.getFairplayCertificate(certificateUrl);
    await keys.setServerCertificate(cert);
    return keys;
  }

  async playFairplay(url: string, drm: Drm): Promise<void> {
    try {
      await this.player!.setMediaKeys(null);
      await this.playClearStream(url);
      this.encryptedEventListener = async (event) => {
        try {
          this.logger.log('encrypted event', event);
          const initDataType = event.initDataType;
          if (initDataType !== 'skd') {
            this.logger.error(
              `Received unexpected initialization data type "${initDataType}"`
            );
            return;
          }

          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (!this.player!.mediaKeys) {
            const keys = await this.getFairplayMediaKeys(
              initDataType,
              drm.data.certificateUrl!
            );
            await this.player!.setMediaKeys(keys);
          }

          const session = this.player!.mediaKeys!.createSession();
          const initData = event.initData;

          await session.generateRequest(initDataType, initData as any);
          const messageEvent = await waitFor(session, 'message');

          const keyURI = uInt8ArrayToString(new Uint8Array(initData as any));
          const response = await this.getLicenseResponse(
            drm.data.licenseUrl,
            drm.headers.Authorization,
            keyURI,
            messageEvent.message
          );

          await session.update(response);
        } catch (e) {
          this.logger.error('error at encrypted event', e);
        }
      };

      this.logger.log('adding encrypted event listener');
      this.player!.addEventListener('encrypted', this.encryptedEventListener);
    } catch (e) {
      this.logger.error('error at playFairplay', e);
    }
  }

  private getHLSWidevineConfig(drm: Drm): Partial<HlsConfig> {
    const licenseUrl = this.getLicenseUrl(drm);

    return {
      drmSystems: {
        'com.widevine.alpha': {
          licenseUrl,
        },
      },
      emeEnabled: true,
    };
  }

  private getDashWidevineConfig(drm: Drm): dashjs.ProtectionDataSet {
    const licenseUrl = this.getLicenseUrl(drm);

    return {
      'com.widevine.alpha': {
        serverURL: licenseUrl,
      },
    };
  }

  async setHlsSource(url: string, drm: Drm | undefined): Promise<void> {
    this.logger.log('html5 with hls.js');
    let config;

    if (drm?.type === 'widevine') {
      config = this.getHLSWidevineConfig(drm);

      this.logger.log(`config: ${JSON.stringify(config)}`);
    }

    const hls = new Hls(config);
    this.currentHlsInstance = hls;
    hls.loadSource(url);
    hls.attachMedia(this.player!);
    this.hlsSourceUrl = url;

    await new Promise((resolve, reject) => {
      hls.on(Hls.Events.MANIFEST_PARSED, (_event, data) => {
        this.logger.log('HLS.js manifest parsed', data);
        if (data.audioTracks.length > 0) {
          this.audioTracks = data.audioTracks;
          this.logger.log('Audio Tracks:', this.audioTracks);
        } else {
          this.logger.log('No audio tracks found.');
          // Handle the absence of audio tracks if needed
        }

        if (data.subtitleTracks.length > 0) {
          this.subtitleTracks = data.subtitleTracks;
          this.logger.log('Subtitle Tracks:', this.subtitleTracks);
        } else {
          this.logger.log('No subtitle tracks found.');
          // Handle the absence of subtitle tracks if needed
        }

        const levels = hls.levels;
        hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
          const currentLevel = data.level;

          this.eventCallback?.({
            key: this.playerId,
            type: 'updateVideoSize',
            bitrate: levels[currentLevel].bitrate,
            size: {
              width: levels[currentLevel].width,
              height: levels[currentLevel].height,
            },
          });
        });
        resolve(data);
      });

      hls.on(Hls.Events.ERROR, (_event, data) => {
        this.logger.log('HLS.js error', data);
        reject(data);

        if (data.response?.code === 429) {
          this.eventCallback?.({ key: this.playerId, type: 'error' });
        }

        if (data.details === 'bufferAppendingError') {
          this.hasBufferAppendingError = true;
          this.logger.log(
            'Attempting to recover from buffer appending error...'
          );
          hls.recoverMediaError();
        } else {
          this.hasBufferAppendingError = false;
        }
      });
    });
  }

  setDashSource(url: string, drm: Drm | undefined): void {
    this.logger.log('html5 with dash.js');

    const dash = dashjs.MediaPlayer().create();

    if (drm?.type === 'widevine') {
      dash.setProtectionData(this.getDashWidevineConfig(drm));
    }

    this.currentDashInstance = dash;
    dash.initialize(this.player, url, true);
  }

  async setSrc(url: string, drm: Drm | undefined): Promise<void> {
    this.logger.log(`setSrc: ${url}  drm: ${JSON.stringify(drm)}`);
    if (this.player == null) {
      this.logger.error('Player not found or initialized');
      await this.init();
      return;
    }

    try {
      this.clearCurrentSource();
      const isHLS = url.includes('.m3u8');
      const isDash = url.includes('.mpd');

      if (drm?.type === 'fairplay') {
        await this.playFairplay(url, drm);
      } else if (isHLS) {
        await this.setHlsSource(url, drm);
      } else if (isDash) {
        this.setDashSource(url, drm);
      } else {
        await this.playClearStream(url);
      }
    } catch (e: any) {
      this.logger.log('error at loading source', e);
      throw new Error(e.toString());
    }
  }

  async pause(): Promise<void> {
    this.logger.log('[Player pause]');
    if (this.player == null) {
      return;
    }
    if (!this.isPaused) {
      this.player.pause();
      this.isPaused = true;
    }
  }

  async play(): Promise<void> {
    this.logger.log('[Player play]');
    if (this.player == null) {
      return;
    }
    this.isPaused = false;
    await this.player.play();
  }

  async stop(): Promise<void> {
    this.logger.log('[Player stop]');
    await this.pause();
    this.currentHlsInstance?.destroy();
    this.currentDashInstance?.reset();
  }

  getDurationInMilliseconds(): number {
    if (this.player == null) {
      return 0;
    }
    return this.player.duration * 1000;
  }

  async position(): Promise<number> {
    if (this.player == null) {
      this.logger.error('Player is null');
      return 0;
    }

    const currentTime = this.player.currentTime * 1000;

    this.logger.log(`position ${currentTime}`);
    return currentTime;
  }

  async seekTo(positionInMSec: number): Promise<void> {
    this.logger.log(`seekTo ${positionInMSec}`);

    let positionInSec = 0;
    if (positionInMSec < 0) {
      this.logger.log('Error setting position to negative');
      return;
    }
    positionInSec = Math.round(positionInMSec / 1000);

    if (this.player == null) {
      this.logger.error('Player is null');
      return;
    }

    this.player.currentTime = positionInSec;
  }

  async setAudioTrack(
    index: number,
    language: string,
    languageCode: string
  ): Promise<void> {
    this.logger.log('[Player setAudioTrack] language: ', language);

    if (this.player == null) {
      return;
    }

    if (this.currentAudioTrackIndex === index) {
      this.logger.log('Audio track already selected: ', index);
      return;
    }

    if (this.currentHlsInstance != null) {
      try {
        this.currentHlsInstance.audioTrack = index;
        this.currentAudioTrackIndex = index;

        if (
          this.hlsSourceUrl !== null &&
          this.hlsSourceUrl !== '' &&
          this.hasBufferAppendingError
        ) {
          this.currentHlsInstance.loadSource(this.hlsSourceUrl);
          this.currentHlsInstance.attachMedia(this.player);
        }
      } catch (e) {
        this.logger.error('Error while setting audio track: ', e);
      }
    } else {
      // audioTracks property only supported via safari
      for (let i = 0; i < (this.player as any).audioTracks.length; i++) {
        const track = (this.player as any).audioTracks[i];
        if (track.label === language) {
          track.enabled = true;
          this.currentAudioTrackIndex = i;
        } else {
          track.enabled = false;
        }
      }
    }
  }

  async setSubtitleTrack(index: number, language: String): Promise<void> {
    this.logger.log('[Player setSubtitleTrack] language: ', language);

    if (this.player == null) {
      return;
    }
    if (this.currentHlsInstance != null) {
      for (const track of this.subtitleTracks) {
        if (track.id === index) {
          this.currentHlsInstance.subtitleTrack = track.id;
          break;
        }
      }
    } else {
      for (let i = 0; i < this.player.textTracks.length; i++) {
        const track = this.player.textTracks[i];
        if (track.label === language) {
          track.mode = 'showing';
        } else {
          track.mode = 'disabled';
        }
      }
    }
  }

  async getSubtitleTracks(): Promise<SubtitleTrack[]> {
    this.logger.log('[Player getSubtitleTracks]');
    if (this.player == null) {
      return [];
    }

    const subtitleTracks: SubtitleTrack[] = [];
    if (this.subtitleTracks.length > 0) {
      for (let i = 0; i < this.subtitleTracks.length; i++) {
        const track = this.subtitleTracks[i];
        const subtitleTrack: SubtitleTrack = {
          index: track.id,
          language: track.name,
        };

        subtitleTracks.push(subtitleTrack);
      }

      return subtitleTracks;
    }

    for (let i = 0; i < this.player?.textTracks.length; i++) {
      const track = this.player.textTracks[i];
      const subtitleTrack: SubtitleTrack = {
        index: i,
        language: track.label,
      };

      subtitleTracks.push(subtitleTrack);
    }

    return subtitleTracks;
  }

  async getQualities(): Promise<Quality[]> {
    this.logger.log('[Player getQualities]');
    // TODO - implement

    return [];
  }

  async setQuality(
    bitrate?: number,
    width?: number,
    height?: number
  ): Promise<void> {
    if (
      this.currentHlsInstance != null &&
      width !== undefined &&
      height !== undefined &&
      width !== 0 &&
      height !== 0 &&
      !isNaN(width) &&
      !isNaN(height)
    ) {
      const levels = this.currentHlsInstance.levels;
      const selectedLevelIndex = levels.findIndex(
        (level) => level.width === width && level.height === height
      );
      this.currentHlsInstance.currentLevel = selectedLevelIndex;
    }

    if (
      this.currentDashInstance != null &&
      width !== undefined &&
      height !== undefined &&
      width !== 0 &&
      height !== 0 &&
      !isNaN(width) &&
      !isNaN(height)
    ) {
      const bitrates = this.currentDashInstance.getBitrateInfoListFor('video');
      const selectedBitrate = bitrates.find(
        (b) => b.width === width && b.height === height
      );
      if (selectedBitrate != null) {
        this.currentDashInstance.setQualityFor(
          'video',
          selectedBitrate.qualityIndex
        );
      }
    }
  }

  async setVolume(volume: number): Promise<void> {
    this.logger.log('[Player setVolume]');
    if (this.player == null) {
      return;
    }
    this.player.volume = volume;
  }

  onEvent(listener: PlayerEventCallback): void {
    this.logger.log('[Player onEvent]');
    this.eventCallback = listener;
  }
}

export { Html5Player };
